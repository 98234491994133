.groups .group .bar {
  min-height: 46px;
  line-height: 46px;
  border-radius: 4px;
  padding-left: 20px;
  margin-top: 4px;
}
.groups .group .title {
  background-color: rgb(57.5384615385, 47.0769230769, 10.4615384615);
  color: rgb(241.9230769231, 228.8461538462, 183.0769230769);
}
.groups .group .add {
  background-color: var(--plus-color-back);
}
.groups .group .add .name {
  margin-right: 8px;
}
.groups .group .add .icon {
  color: var(--plus-color-icon);
}
.groups .group .add.selected {
  background-color: var(--plus-color-selected);
}
.groups .group .edit {
  display: inline-block;
  width: 32px;
  height: 32px;
  line-height: 32px;
  margin-top: 7px;
  text-align: center;
  margin-right: 8px;
  background-color: rgb(241.9230769231, 228.8461538462, 183.0769230769);
  color: rgb(57.5384615385, 47.0769230769, 10.4615384615);
  border-radius: 4px;
  float: right;
  cursor: pointer;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9zcmMvdmlld3MvZ3JvdXBzIiwic291cmNlcyI6WyJHcm91cHNWaWV3LnNhc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBYUk7RUFDRSxZQUxVO0VBTVYsYUFOVTtFQU9WO0VBQ0E7RUFDQTs7QUFDRjtFQUNFLGtCQWZPO0VBZ0JQLE9BZFU7O0FBZVo7RUFDRTs7QUFDQTtFQUNFOztBQUNGO0VBQ0U7O0FBQ0Y7RUFDRTs7QUFDSjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0Esa0JBL0JVO0VBZ0NWLE9BbENPO0VBbUNQO0VBQ0E7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIkB1c2UgXCJzYXNzOmNvbG9yXCJcblxuQHVzZSAnLi4vLi4vdWkvc2hvcC5zYXNzJ1xuXG4kZ3JvdXAtY29sb3I6ICNiOTJcbiRncm91cC1kYXJrOiBjb2xvci5hZGp1c3QoJGdyb3VwLWNvbG9yLCAkbGlnaHRuZXNzOiAtMzAlLCAkc3BhY2U6IGhzbClcbiRncm91cC1saWdodDogY29sb3IuYWRqdXN0KCRncm91cC1jb2xvciwgJGxpZ2h0bmVzczogMzAlLCAkc3BhY2U6IGhzbClcbiRncm91cC1saWdodGVyOiBjb2xvci5hZGp1c3QoJGdyb3VwLWNvbG9yLCAkbGlnaHRuZXNzOiA0MCUsICRzcGFjZTogaHNsKVxuXG4kaGVhZGVyLWhlaWdodDogNDZweFxuXG4uZ3JvdXBzXG4gIC5ncm91cFxuICAgIC5iYXJcbiAgICAgIG1pbi1oZWlnaHQ6ICRoZWFkZXItaGVpZ2h0XG4gICAgICBsaW5lLWhlaWdodDogJGhlYWRlci1oZWlnaHRcbiAgICAgIGJvcmRlci1yYWRpdXM6IDRweFxuICAgICAgcGFkZGluZy1sZWZ0OiAyMHB4XG4gICAgICBtYXJnaW4tdG9wOiA0cHhcbiAgICAudGl0bGVcbiAgICAgIGJhY2tncm91bmQtY29sb3I6ICRncm91cC1kYXJrXG4gICAgICBjb2xvcjogJGdyb3VwLWxpZ2h0ZXJcbiAgICAuYWRkXG4gICAgICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1wbHVzLWNvbG9yLWJhY2spXG4gICAgICAubmFtZVxuICAgICAgICBtYXJnaW4tcmlnaHQ6IDhweFxuICAgICAgLmljb25cbiAgICAgICAgY29sb3I6IHZhcigtLXBsdXMtY29sb3ItaWNvbilcbiAgICAgICYuc2VsZWN0ZWRcbiAgICAgICAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tcGx1cy1jb2xvci1zZWxlY3RlZClcbiAgICAuZWRpdFxuICAgICAgZGlzcGxheTogaW5saW5lLWJsb2NrXG4gICAgICB3aWR0aDogMzJweFxuICAgICAgaGVpZ2h0OiAzMnB4XG4gICAgICBsaW5lLWhlaWdodDogMzJweFxuICAgICAgbWFyZ2luLXRvcDogN3B4XG4gICAgICB0ZXh0LWFsaWduOiBjZW50ZXJcbiAgICAgIG1hcmdpbi1yaWdodDogOHB4XG4gICAgICBiYWNrZ3JvdW5kLWNvbG9yOiAkZ3JvdXAtbGlnaHRlclxuICAgICAgY29sb3I6ICRncm91cC1kYXJrXG4gICAgICBib3JkZXItcmFkaXVzOiA0cHhcbiAgICAgIGZsb2F0OiByaWdodFxuICAgICAgY3Vyc29yOiBwb2ludGVyXG4iXX0= */