.element {
  background-color: var(--group-color-back);
  color: var(--group-color-text);
}
.element .name {
  text-align: left;
}
.element .notes {
  text-align: right;
  padding-right: 5px;
}

.selected {
  background-color: var(--group-color-selected);
}

.badge {
  background-color: var(--group-color-badge);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9zcmMvdmlld3Mvc2hvcCIsInNvdXJjZXMiOlsiR3JvdXBDaG9vc2VyLnNhc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBTUE7RUFDRTtFQUNBOztBQUNBO0VBQ0U7O0FBQ0Y7RUFDRTtFQUNBOzs7QUFFSjtFQUNFOzs7QUFFRjtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiQHVzZSBcInNhc3M6Y29sb3JcIlxuXG5AdXNlICcuLi8uLi91aS9zaG9wJ1xuXG4kdGV4dC1wYWRkaW5nOiA0cHhcblxuLmVsZW1lbnRcbiAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tZ3JvdXAtY29sb3ItYmFjaylcbiAgY29sb3I6IHZhcigtLWdyb3VwLWNvbG9yLXRleHQpXG4gIC5uYW1lXG4gICAgdGV4dC1hbGlnbjogbGVmdFxuICAubm90ZXNcbiAgICB0ZXh0LWFsaWduOiByaWdodFxuICAgIHBhZGRpbmctcmlnaHQ6ICR0ZXh0LXBhZGRpbmcgKyAxXG5cbi5zZWxlY3RlZFxuICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1ncm91cC1jb2xvci1zZWxlY3RlZClcblxuLmJhZGdlXG4gIGJhY2tncm91bmQtY29sb3I6IHZhcigtLWdyb3VwLWNvbG9yLWJhZGdlKVxuIl19 */