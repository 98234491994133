.meal {
  background-color: var(--meal-color-back);
  color: var(--meal-color-text);
}
.meal .icon {
  color: var(--meal-color-icon);
}
.meal.selected {
  background-color: var(--meal-color-selected);
}

.add {
  background-color: var(--plus-color-back);
}
.add .name {
  margin-right: 8px;
}
.add .icon {
  color: var(--plus-color-icon);
}
.add.selected {
  background-color: var(--plus-color-selected);
}

.match {
  color: var(--meal-color-match);
  background-color: var(--meal-color-match-back);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9zcmMvdmlld3MvbWVhbHMiLCJzb3VyY2VzIjpbIk1lYWxUYWcuc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFJQTtFQUNFO0VBQ0E7O0FBQ0E7RUFDRTs7QUFDRjtFQUNFOzs7QUFDSjtFQUNFOztBQUNBO0VBQ0U7O0FBQ0Y7RUFDRTs7QUFDRjtFQUNFOzs7QUFDSjtFQUNFO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyJAdXNlIFwic2Fzczpjb2xvclwiXG5cbkB1c2UgJy4uLy4uL3VpL3Nob3AnXG5cbi5tZWFsXG4gIGJhY2tncm91bmQtY29sb3I6IHZhcigtLW1lYWwtY29sb3ItYmFjaylcbiAgY29sb3I6IHZhcigtLW1lYWwtY29sb3ItdGV4dClcbiAgLmljb25cbiAgICBjb2xvcjogdmFyKC0tbWVhbC1jb2xvci1pY29uKVxuICAmLnNlbGVjdGVkXG4gICAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tbWVhbC1jb2xvci1zZWxlY3RlZClcbi5hZGRcbiAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tcGx1cy1jb2xvci1iYWNrKVxuICAubmFtZVxuICAgIG1hcmdpbi1yaWdodDogOHB4XG4gIC5pY29uXG4gICAgY29sb3I6IHZhcigtLXBsdXMtY29sb3ItaWNvbilcbiAgJi5zZWxlY3RlZFxuICAgIGJhY2tncm91bmQtY29sb3I6IHZhcigtLXBsdXMtY29sb3Itc2VsZWN0ZWQpXG4ubWF0Y2hcbiAgY29sb3I6IHZhcigtLW1lYWwtY29sb3ItbWF0Y2gpXG4gIGJhY2tncm91bmQtY29sb3I6IHZhcigtLW1lYWwtY29sb3ItbWF0Y2gtYmFjaylcbiJdfQ== */