.pill {
  height: var(--clickable-height);
  min-height: var(--clickable-height);
}

.ingredient {
  background-color: var(--ingredient-color-back);
  color: var(--ingredient-color-text);
  white-space: nowrap;
}
.ingredient .icon {
  color: var(--ingredient-color-icon);
}
.ingredient.selected {
  background-color: var(--ingredient-color-selected);
}

.add {
  background-color: var(--plus-color-back);
}
.add .name {
  margin-right: 8px;
}
.add .icon {
  color: var(--plus-color-icon);
}
.add.selected {
  background-color: var(--plus-color-selected);
}

.match {
  color: var(--ingredient-color-match);
  background-color: var(--ingredient-color-match-back);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9zcmMvdmlld3MvaW5ncmVkaWVudHMiLCJzb3VyY2VzIjpbIkluZ3JlZGllbnRUYWcuc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNFO0VBQ0E7OztBQUNGO0VBQ0U7RUFDQTtFQUNBOztBQUNBO0VBQ0U7O0FBQ0Y7RUFDRTs7O0FBRUo7RUFDRTs7QUFDQTtFQUNFOztBQUNGO0VBQ0U7O0FBQ0Y7RUFDRTs7O0FBQ0o7RUFDRTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiQHVzZSBcInNhc3M6Y29sb3JcIlxuXG4ucGlsbFxuICBoZWlnaHQ6IHZhcigtLWNsaWNrYWJsZS1oZWlnaHQpXG4gIG1pbi1oZWlnaHQ6IHZhcigtLWNsaWNrYWJsZS1oZWlnaHQpXG4uaW5ncmVkaWVudFxuICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1pbmdyZWRpZW50LWNvbG9yLWJhY2spXG4gIGNvbG9yOiB2YXIoLS1pbmdyZWRpZW50LWNvbG9yLXRleHQpXG4gIHdoaXRlLXNwYWNlOiBub3dyYXBcbiAgLmljb25cbiAgICBjb2xvcjogdmFyKC0taW5ncmVkaWVudC1jb2xvci1pY29uKVxuICAmLnNlbGVjdGVkXG4gICAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0taW5ncmVkaWVudC1jb2xvci1zZWxlY3RlZClcblxuLmFkZFxuICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1wbHVzLWNvbG9yLWJhY2spXG4gIC5uYW1lXG4gICAgbWFyZ2luLXJpZ2h0OiA4cHhcbiAgLmljb25cbiAgICBjb2xvcjogdmFyKC0tcGx1cy1jb2xvci1pY29uKVxuICAmLnNlbGVjdGVkXG4gICAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tcGx1cy1jb2xvci1zZWxlY3RlZClcbi5tYXRjaFxuICBjb2xvcjogdmFyKC0taW5ncmVkaWVudC1jb2xvci1tYXRjaClcbiAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0taW5ncmVkaWVudC1jb2xvci1tYXRjaC1iYWNrKVxuIl19 */