.resultIngredient {
  padding: 0px 4px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  display: inline-block;
  font-size: 12px;
  margin-left: 8px;
}
.resultIngredient .icon {
  display: inline;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  top: 0px;
}
.resultIngredient .amount {
  display: inline;
  color: rgba(255, 255, 255, 0.7);
  font-weight: bold;
}

.ingredientSelected {
  border-color: #30c030;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9zcmMvdmlld3Mvc2hvcCIsInNvdXJjZXMiOlsiQnV5YWJsZVNlYXJjaFJlc3VsdC5zYXNzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUNBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBQ0Y7RUFDRTtFQUNBO0VBQ0E7OztBQUVKO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyIucmVzdWx0SW5ncmVkaWVudFxuICBwYWRkaW5nOiAwcHggNHB4XG4gIGJhY2tncm91bmQtY29sb3I6IHJnYmEoMCwgMCwgMCwgMC40KVxuICBib3JkZXItcmFkaXVzOiA0cHhcbiAgZGlzcGxheTogaW5saW5lLWJsb2NrXG4gIGZvbnQtc2l6ZTogMTJweFxuICBtYXJnaW4tbGVmdDogOHB4XG4gIC5pY29uXG4gICAgZGlzcGxheTogaW5saW5lXG4gICAgY29sb3I6IHJnYmEoMCwgMCwgMCwgMC41KVxuICAgIGZvbnQtc2l6ZTogMTRweFxuICAgIHRvcDogMHB4XG4gIC5hbW91bnRcbiAgICBkaXNwbGF5OiBpbmxpbmVcbiAgICBjb2xvcjogcmdiYSgyNTUsIDI1NSwgMjU1LCAwLjcpXG4gICAgZm9udC13ZWlnaHQ6IGJvbGRcblxuLmluZ3JlZGllbnRTZWxlY3RlZFxuICBib3JkZXItY29sb3I6ICMzMGMwMzBcblxuIl19 */