import { useQuery, useMutation } from '@apollo/client'

import { Buy } from '../../models/buy'

export const useJustBought = () => {
  const { data } = useQuery(Buy.fetchJustBought)
  const justBought = data && data.justBought

  const [ boughtAddition ] = Buy.useJustBoughtUpdate()
  const [ buyAdd ] = useMutation(Buy.methods.add)

  return { justBought, boughtAddition, buyAdd } 
}
