.diet {
  width: 100%;
}
.diet .header {
  min-height: calc(var(--clickable-height-2px));
  background-color: #ccc;
  border-radius: 8px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  padding: 2px 4px;
}
.diet .header .name {
  flex: 2 auto;
  font-weight: 700;
  font-size: 24px;
  color: #000;
  padding-left: 12px;
  line-height: var(--clickable-height);
}
.diet .header .controls {
  flex: 1 auto;
  text-align: right;
  margin-right: 8px;
}
.diet .meals {
  display: flex;
  flex-flow: row wrap;
}
.diet .meals .meal {
  flex: 1 auto;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9zcmMvdmlld3MvZGlldHMiLCJzb3VyY2VzIjpbIkRpZXRWaWV3LnNhc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTs7QUFDQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUNBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUNGO0VBQ0U7RUFDQTtFQUNBOztBQUNKO0VBQ0U7RUFDQTs7QUFDQTtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiLmRpZXRcbiAgd2lkdGg6IDEwMCVcbiAgLmhlYWRlclxuICAgIG1pbi1oZWlnaHQ6IGNhbGModmFyKC0tY2xpY2thYmxlLWhlaWdodCAtIDJweCkpXG4gICAgYmFja2dyb3VuZC1jb2xvcjogI2NjY1xuICAgIGJvcmRlci1yYWRpdXM6IDhweFxuICAgIG1hcmdpbi1ib3R0b206IDRweFxuICAgIGRpc3BsYXk6IGZsZXhcbiAgICBhbGlnbi1pdGVtczogY2VudGVyXG4gICAgcGFkZGluZzogMnB4IDRweFxuICAgIC5uYW1lXG4gICAgICBmbGV4OiAyIGF1dG9cbiAgICAgIGZvbnQtd2VpZ2h0OiA3MDBcbiAgICAgIGZvbnQtc2l6ZTogMjRweFxuICAgICAgY29sb3I6ICMwMDBcbiAgICAgIHBhZGRpbmctbGVmdDogMTJweFxuICAgICAgbGluZS1oZWlnaHQ6IHZhcigtLWNsaWNrYWJsZS1oZWlnaHQpXG4gICAgLmNvbnRyb2xzXG4gICAgICBmbGV4OiAxIGF1dG9cbiAgICAgIHRleHQtYWxpZ246IHJpZ2h0XG4gICAgICBtYXJnaW4tcmlnaHQ6IDhweFxuICAubWVhbHNcbiAgICBkaXNwbGF5OiBmbGV4XG4gICAgZmxleC1mbG93OiByb3cgd3JhcFxuICAgIC5tZWFsXG4gICAgICBmbGV4OiAxIGF1dG9cbiJdfQ== */