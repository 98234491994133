.table {
  width: 100%;
}
.table .tr {
  border-radius: 2px;
}
.table .added {
  background-color: #cfc;
  color: #006600;
}
.table .removed {
  background-color: #fcc;
  color: #660000;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9zcmMvdmlld3MvaGlzdG9yeSIsInNvdXJjZXMiOlsiSGlzdG9yeVZpZXcuc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFRQTtFQUNFOztBQUNBO0VBQ0U7O0FBQ0Y7RUFDRSxrQkFYYTtFQVliLE9BVGE7O0FBVWY7RUFDRSxrQkFiZTtFQWNmLE9BWGUiLCJzb3VyY2VzQ29udGVudCI6WyJAdXNlIFwic2Fzczpjb2xvclwiXG5cbiRhZGRlZC1jb2xvci1iZzogI2NmY1xuJHJlbW92ZWQtY29sb3ItYmc6ICNmY2NcblxuJGFkZGVkLWNvbG9yLWZnOiBjb2xvci5hZGp1c3QoJGFkZGVkLWNvbG9yLWJnLCAkbGlnaHRuZXNzOiAtNzAlLCAkc3BhY2U6IGhzbClcbiRyZW1vdmVkLWNvbG9yLWZnOiBjb2xvci5hZGp1c3QoJHJlbW92ZWQtY29sb3ItYmcsICRsaWdodG5lc3M6IC03MCUsICRzcGFjZTogaHNsKVxuXG4udGFibGVcbiAgd2lkdGg6IDEwMCVcbiAgLnRyXG4gICAgYm9yZGVyLXJhZGl1czogMnB4XG4gIC5hZGRlZFxuICAgIGJhY2tncm91bmQtY29sb3I6ICRhZGRlZC1jb2xvci1iZ1xuICAgIGNvbG9yOiAkYWRkZWQtY29sb3ItZmdcbiAgLnJlbW92ZWRcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiAkcmVtb3ZlZC1jb2xvci1iZ1xuICAgIGNvbG9yOiAkcmVtb3ZlZC1jb2xvci1mZ1xuIl19 */