import { useState, useRef } from 'react';
import {
  Popover,
  PopoverHeader,
  PopoverBody
} from 'reactstrap'
import classnames from 'classnames'

import { tr } from 'react-hook-form-auto'

import { BuyForm } from './BuyForm'
import { BuyableSearchForm } from './BuyableSearchForm'
import { IngredientAmounts } from '../ingredients/IngredientAmounts'
import { OriginsInfo } from './origins/OriginsInfo'
import { GroupChooser } from './GroupChooser'
import { ListTransition } from '../../ui/transitions/ListTransition'
import { JustBoughtView } from './JustBoughtView'
import { useShopData } from './shopData'
import { BuyableSearchResult } from './BuyableSearchResult'

import { IngredientGroup } from '../../models/ingredientGroup'

import styles from './ShopView.sass'
import { Button } from '../../ui/bootstrap/Button';

const formName = 'buy-update'

// Used to filter transKey
const Diver = ({ className, children }) =>
  <div className={className}>
    {children}
  </div>

const GroupDiv = ({ groupClasses, name, children }) =>
  <div
    className={groupClasses}
    key={name}
  >
    {children}
  </div>

export const ShopView = () => {
  const {
    buys,
    doAdd,
    doBuy,
    postpone,
    ingredientUpdate
  } = useShopData()

  const [ editActive, setEditActive ] = useState(false)
  const [ editingBuy, setEditingBuy ] = useState(false)
  const [ selectedGroup, setSelectedGroup ] = useState('')
  const [ showOrigin, setShowOrigin ] = useState('')
  const ingredientSearch = useRef(null)

  const buysByIngredientId = buys && buys.reduce((hash, buy) => {
    const { ingredient: { id } } = buy
    hash[id] = buy

    return hash
  }, {}) || {}

  const editBuy = (buy) => {
    const { ingredient } = buy
    const { group } = ingredient

    setEditingBuy(buy)
    setSelectedGroup(group)

    editToggle()
  }

  const editToggle = () => {
    setEditActive(!editActive)
  }

  const editClose = () => {
    setEditActive(false)
    setSelectedGroup('')
  }

  const handleGroupSelect = (name) => {
    if (name == selectedGroup)
      setSelectedGroup('')
    else
      setSelectedGroup(name)
  }

  const renderOrigins = (buy, cssID) => {
    if (buy && buy.id == showOrigin) {
      return (
        <Popover
          placement="bottom"
          isOpen={true}
          toggle={handleShowOrigins}
          target={cssID}
        >
          <PopoverHeader>
            {tr('models.origins')}
          </PopoverHeader>
          <PopoverBody>
            <OriginsInfo buy={buy} />
          </PopoverBody>
        </Popover>
      )
    }
  }

  const handleAdd = (doc) => {
    const type = doc.__typename == 'Ingredient' ? 'ingredient' : 'meal'
    const isIngredient = type == 'ingredient'

    const add = id => {
      doAdd({
        type,
        unit: 'u',
        amount: 1,
        ingredientName: isIngredient && doc.name || '',
        id
      })
    }

    if (doc === 'add')
      editToggle()
    else {
      const { id } = doc
      if (id) {
        add(id)
      } else {
        if (isIngredient) {
          ingredientUpdate(doc).then(({ data }) => {
            add(data.ingredientUpdate.id)
          })
        }
      }

      ingredientSearch.current.reset()
    }
  } 

  const handleCreate = (doc) => {
    handleAdd({
      ...doc,
      __typename: 'Ingredient'
    })
  }

  const handleShowOrigins = (buyId, e) => {
    console.log("HANDLE SHOW", buyId)
    setShowOrigin(buyId)
  }

  const renderOriginsButton = (buy) => {
    const { id } = buy
    const cssID = `toggle-origins-${id}`

    const buttonProps = {
      id: cssID,
      iconClass: 'clipboard-list',
      className: classnames(styles.button),
      type: 'info'
    }

    if (buy.origins && buy.origins.length > 0) {
      buttonProps.onClick = handleShowOrigins.bind(this, id)

      return (
        <div>
          <Button
            {...buttonProps}
          />
          {renderOrigins(buy, cssID)}
        </div>
      )
    } else {
      return (
        <Button
          {...buttonProps}
          disabled
        />
      )
    }
  }

  const renderIngredient = (buy) => {
    const { ingredient } = buy

    const handleEdit = editBuy.bind(null, buy)
    const handlePostpone = () => {
      const { id } = buy

      postpone(id)
    }
    const ingredientName = ingredient.name

    if (ingredient) {
      return (
        <div
          className={styles.ingredient}
        >
          <div
            key="buy"
            className={styles.ingredientBuy}
          >
            <Button
              iconClass="check"
              className={styles.buyButton}
              type="primary"
              outline
              onClick={() => doBuy(buy.id)}
            />
          </div>
          <div
            key="name"
            className={styles.ingredientName}
          >
            {ingredientName}
          </div>
          <IngredientAmounts
            amountClassName={styles.amount}
            className={styles.amounts}
            buy={buy}
          />
          <div className={styles.buttons}>
            <Button
              iconClass="hourglass-start"
              className={styles.postponeButton}
              onClick={handlePostpone}
              outline
            />
            {renderOriginsButton(buy)}
            <div>
              <Button
                iconClass="bars"
                className={styles.button}
                onClick={handleEdit}
              />
            </div>
          </div>
        </div>
      )
    } else {
      // FIXME this should not happen
      // FIXME hahahaha
      return "---"
    }
  }

  const renderListElement = (buy) => {
    return (
      <Diver 
        key={buy.id}
        transKey={buy.id}
        className={styles.item}
      >
        {renderIngredient(buy)}
      </Diver>
    )
  }

  const renderIngredientList = (buys) => {
    return buys.map(renderListElement)
  }

  const renderList = (groupData) => {
    if (groupData) {
      const groupNames = Object.keys(groupData).sort()
      const selected = selectedGroup

      let list
      if (selected) {
        list = [selected]
        groupNames.forEach(groupName => {
          if (groupName != selected)
            list.push(groupName)
        })
      } else
        list = groupNames

      return list.map(name => {
        const buys = groupData[name]

        const ghosted = selected && selected != name
        const groupClasses = classnames(styles.group, {
          [styles.ghosted]: ghosted
        })

        if (buys && buys.length > 0) {
          return (
            <GroupDiv
              className={groupClasses}
              key={name}
              transKey={name}
            >
              <div className={styles.header}>
                <div className={styles.name}>
                  {name} 
                </div>
              </div>
              <ListTransition
                className={styles.buys}
                styles={styles}
              >
                {renderIngredientList(buys)}
              </ListTransition>
            </GroupDiv>
          )
        } else
          return null
      })
    } else
      return null
  }

  function handleSubmit() {
    setSelectedGroup('')
  }

  // render()
  const groupData = buys && IngredientGroup.processGroups(buys)

  return (
    <div key="shop-view" className={styles.shop}>
      {
        editingBuy &&
          <BuyForm
            form={formName}
            onClose={editClose}
            visible={editActive}
            doc={editingBuy}
            ingredient={editingBuy && editingBuy.ingredient}
            initialGroupId={editingBuy && editingBuy.groupId}
            didSubmit={handleSubmit}
          /> 
      }
      <BuyableSearchForm
        onSelect={handleAdd}
        onCreate={handleCreate}
        buysByIngredientId={buysByIngredientId}
        buyableRenderer={BuyableSearchResult}
        ref={element => { ingredientSearch.current = element }}
        autoFocus
      />
      <GroupChooser
        groupData={groupData}
        selected={selectedGroup}
        onSelect={handleGroupSelect}
      />
      <ListTransition className={styles.buys} styles={styles}>
        {renderList(groupData)}
      </ListTransition>
      <JustBoughtView />
    </div>
  )
}
