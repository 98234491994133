import { useState } from 'react';
import { tr } from 'react-hook-form-auto'
import { Buy } from '../../models/buy'

import { Icon } from '../../ui/bootstrap/bootstrap'

import { useJustBought } from './useJustBought'
import styles from './JustBoughtView.sass'
import { renderDate } from '../../pintor';

export const JustBoughtView = () => {
  const { justBought } = useJustBought()
  const [ boughtReadd ] = Buy.useBoughtAdd()
  const [ visible, setVisible ] = useState(false)

  const renderRecents = list =>
    list.map(bought => {
      const handleClick = () => {
        return boughtReadd({
          variables: {
            id: bought.id
          }
        })
      }

      return (
        <div key={bought.id} className={styles.item} onClick={handleClick}>
          <div key="date" className={styles.date}>
            {renderDate(bought.createdAt)}
          </div>
          <div key="ingredient" className={styles.ingredient}>
            {bought.ingredient.name}
          </div>
        </div>
      )
    })

  return (
    <div className={styles.group}>
      <div
        className={styles.header}
        onClick={() => setVisible(!visible)}
      >
        <Icon
          icon="first-aid"
        />
        <span className={styles.text}>
          {tr('shop.justBought')}
        </span>
        <span className={styles.folded}>
          <Icon icon={visible && 'chevron-up' || 'chevron-down'} />
        </span>
      </div>
      {visible &&
        <div className={styles.recent}>
          {renderRecents(justBought)}
        </div>
       || null
      }
    </div>
  )
}
