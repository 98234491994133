.nav {
  background-color: #ff7f50;
}
.nav.selected {
  background-color: rgb(255, 164.3028571429, 131);
  font-weight: 600;
}

.add {
  background-color: #686;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9zcmMvdmlld3MvZGlldHMiLCJzb3VyY2VzIjpbIkRpZXRzTmF2LnNhc3MiLCIuLi8uLi91aS9zaG9wLnNhc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBT0E7RUFDRSxrQkFKTTs7QUFLTjtFQUNFLGtCQUxPO0VBTVA7OztBQUNKO0VBQ0Usa0JDVFciLCJzb3VyY2VzQ29udGVudCI6WyJAdXNlIFwic2Fzczpjb2xvclwiXG5cbkB1c2UgJy4uLy4uL3VpL3Nob3AnXG5cbiRjb2xvcjogI2ZmN2Y1MFxuJGNvbG9yLWhpOiBjb2xvci5hZGp1c3QoJGNvbG9yLCAkbGlnaHRuZXNzOiAxMCUsICRzcGFjZTogaHNsKVxuXG4ubmF2XG4gIGJhY2tncm91bmQtY29sb3I6ICRjb2xvclxuICAmLnNlbGVjdGVkXG4gICAgYmFja2dyb3VuZC1jb2xvcjogJGNvbG9yLWhpXG4gICAgZm9udC13ZWlnaHQ6IDYwMFxuLmFkZFxuICBiYWNrZ3JvdW5kLWNvbG9yOiBzaG9wLiRwbHVzLWNvbG9yXG4iLCJAdXNlIFwic2Fzczpjb2xvclwiXG5cbiRtZWFsLWNvbG9yOiAjODY2XG4kaW5ncmVkaWVudC1jb2xvcjogIzk5NFxuJHBsdXMtY29sb3I6ICM2ODZcbiRncm91cC1jb2xvcjogIzY4OFxuXG5AZnVuY3Rpb24gYWRqdXN0LWhzbCgkY29sb3IsICRsaWdodG5lc3M6IG51bGwpXG4gIEByZXR1cm4gY29sb3IuYWRqdXN0KCRjb2xvciwgJGxpZ2h0bmVzczogJGxpZ2h0bmVzcywgJHNwYWNlOiBoc2wpXG5cbi8vIFRPRE86IENvbnZlcnQgdGhvc2UgdG8gdXNlIHRoZSBuZXcgc3lzdGVtXG4kbWVhbC1jb2xvci1kYXJrOiBhZGp1c3QtaHNsKCRtZWFsLWNvbG9yLCAkbGlnaHRuZXNzOiAtMzAlKVxuJGluZ3JlZGllbnQtY29sb3ItZGFyazogYWRqdXN0LWhzbCgkaW5ncmVkaWVudC1jb2xvciwgJGxpZ2h0bmVzczogLTE1JSlcbiRwbHVzLWNvbG9yLWRhcms6IGFkanVzdC1oc2woJHBsdXMtY29sb3IsICRsaWdodG5lc3M6IC0xNSUpXG4kZ3JvdXAtY29sb3ItZGFyazogYWRqdXN0LWhzbCgkZ3JvdXAtY29sb3IsICRsaWdodG5lc3M6IC0zMCUpXG5cbiRtZWFsLWNvbG9yLWxpZ2h0OiBhZGp1c3QtaHNsKCRtZWFsLWNvbG9yLCAkbGlnaHRuZXNzOiAzMCUpXG4kaW5ncmVkaWVudC1jb2xvci1saWdodDogYWRqdXN0LWhzbCgkaW5ncmVkaWVudC1jb2xvciwgJGxpZ2h0bmVzczogMzAlKVxuJHBsdXMtY29sb3ItbGlnaHQ6IGFkanVzdC1oc2woJHBsdXMtY29sb3IsICRsaWdodG5lc3M6IDQwJSlcbiRncm91cC1jb2xvci1saWdodDogYWRqdXN0LWhzbCgkZ3JvdXAtY29sb3IsICRsaWdodG5lc3M6IDMwJSlcbiJdfQ== */