import { useQuery, useMutation } from '@apollo/client'

import { Ingredient } from '../../models/ingredient'
import { Buy } from '../../models/buy'

export const useShopData = () => {
  const { data , loading } = useQuery(Buy.fetch, {
    fetchPolicy: 'cache-and-network'
  })
  const buys = data && data.buys

  Buy.useBuyUpdate()
  Buy.useBuyBuyUpdate()
  const [ ingredientUpdate ] = useMutation(Ingredient.update)
  const [ buyAdd ] = useMutation(Buy.methods.add)
  const [ buyBuy ] = useMutation(Buy.methods.buy)
  const [ buyPostpone ] = useMutation(Buy.methods.buyPostpone, {
    refetchQueries: () => [ 'BuysList' ]
  })

  return {
    buys,
    loading,
    doAdd: variables =>
      buyAdd({
        variables: {
          input: variables
        }
      }),
    doBuy: id =>
      buyBuy({
        variables: { id },
        update: (proxy, { data: buyData }) => {
          const buyBuy = buyData && buyData.buyBuy
          if (buyBuy) {
            const id = proxy.identify(buyBuy)
            proxy.evict({ id })
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          buyBuy: {
            __typename: 'Buy',
            amounts: [],
            origins: [],
            ingredient: null,
            group: null,
            id
          }
        }
      }),
    postpone: (id, seconds) =>
      buyPostpone({
        variables: { id, seconds }
      }),
    ingredientUpdate: doc =>
      ingredientUpdate(Buy.formConvert(doc))
  }
}
