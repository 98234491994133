.wrapper {
  margin-bottom: 16px;
  display: flex;
  flex-flow: row wrap;
}
.wrapper .element {
  flex: 1 auto;
  margin: 2px;
  padding: 10px;
  height: var(--clickable-height);
  min-height: var(--clickable-height);
  line-height: 28px;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 1px 1px 2px #888;
  text-align: center;
}
.wrapper .element .contents {
  display: flex;
  flex-flow: row wrap;
}
.wrapper .element .contents .contentItem {
  flex: 1 auto;
}

:global(.dark) .wrapper .element {
  box-shadow: -1px -1px 2px #666;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9zcmMvY29tcG9uZW50cyIsInNvdXJjZXMiOlsiRmxleExpc3Quc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBQ0E7RUFDQTs7QUFDQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUNBO0VBQ0U7RUFDQTs7QUFDQTtFQUNFOzs7QUFFUjtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiLndyYXBwZXJcbiAgbWFyZ2luLWJvdHRvbTogMTZweFxuICBkaXNwbGF5OiBmbGV4XG4gIGZsZXgtZmxvdzogcm93IHdyYXBcbiAgLmVsZW1lbnRcbiAgICBmbGV4OiAxIGF1dG9cbiAgICBtYXJnaW46IDJweFxuICAgIHBhZGRpbmc6IDEwcHhcbiAgICBoZWlnaHQ6IHZhcigtLWNsaWNrYWJsZS1oZWlnaHQpXG4gICAgbWluLWhlaWdodDogdmFyKC0tY2xpY2thYmxlLWhlaWdodClcbiAgICBsaW5lLWhlaWdodDogMjhweFxuICAgIGN1cnNvcjogcG9pbnRlclxuICAgIGJvcmRlci1yYWRpdXM6IDRweFxuICAgIGJveC1zaGFkb3c6IDFweCAxcHggMnB4ICM4ODhcbiAgICB0ZXh0LWFsaWduOiBjZW50ZXJcbiAgICAuY29udGVudHNcbiAgICAgIGRpc3BsYXk6IGZsZXhcbiAgICAgIGZsZXgtZmxvdzogcm93IHdyYXBcbiAgICAgIC5jb250ZW50SXRlbVxuICAgICAgICBmbGV4OiAxIGF1dG9cblxuOmdsb2JhbCguZGFyaykgLndyYXBwZXIgLmVsZW1lbnRcbiAgYm94LXNoYWRvdzogLTFweCAtMXB4IDJweCAjNjY2XG4iXX0= */